var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "bg-color rounded-lg py-3 " },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "py-2", attrs: { cols: "4" } },
            [
              _c(
                "b-form-select",
                {
                  attrs: { size: "sm" },
                  scopedSlots: _vm._u([
                    {
                      key: "first",
                      fn: function() {
                        return [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: null, disabled: "" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("admin.bundles.select")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.bundleProduct.id,
                    callback: function($$v) {
                      _vm.$set(_vm.bundleProduct, "id", $$v)
                    },
                    expression: "bundleProduct.id"
                  }
                },
                _vm._l(_vm.products, function(product) {
                  return _c(
                    "b-form-select-option",
                    {
                      key: product.label,
                      attrs: { label: product.label, value: product.prod_id }
                    },
                    [_vm._v(" " + _vm._s(product.prod_desc) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "py-2", attrs: { cols: "3" } },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "dark" },
                  on: {
                    click: function($event) {
                      return _vm.openModalBranches("origin")
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("admin.bundles.chooseOrigins")) + " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.bundleProduct.isSingleBusiness
            ? _c(
                "b-col",
                { staticClass: "py-2", attrs: { cols: "5" } },
                [
                  _c(
                    "b-input-group",
                    {
                      attrs: {
                        prepend:
                          "O: " +
                          _vm.bundleProduct.percentageOrigin.toString() +
                          "%",
                        append:
                          "D: " +
                          (
                            100 - _vm.bundleProduct.percentageOrigin
                          ).toString() +
                          "%"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "range-2",
                          number: "",
                          type: "range",
                          min: "0",
                          max: "100"
                        },
                        model: {
                          value: _vm.bundleProduct.percentageOrigin,
                          callback: function($$v) {
                            _vm.$set(_vm.bundleProduct, "percentageOrigin", $$v)
                          },
                          expression: "bundleProduct.percentageOrigin"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "py-2", attrs: { cols: "4" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { switch: "" },
                  model: {
                    value: _vm.bundleProduct.isSingleBusiness,
                    callback: function($$v) {
                      _vm.$set(_vm.bundleProduct, "isSingleBusiness", $$v)
                    },
                    expression: "bundleProduct.isSingleBusiness"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("admin.bundles.singleBusiness")) + " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "py-2", attrs: { cols: "3" } },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "dark" },
                  on: {
                    click: function($event) {
                      return _vm.openModalBranches("destination")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("admin.bundles.chooseDestinations")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "py-2", attrs: { cols: "5" } },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "primary" },
                  on: { click: _vm.addProduct }
                },
                [_vm._v(" " + _vm._s(_vm.$t("admin.bundles.addProduct")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "button-size": "sm",
            "cancel-variant": "danger",
            "ok-title": "Add",
            "ok-variant": "primary",
            size: "sm",
            scrollable: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [_c("h6", [_vm._v("Choose branches")])]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modalBranches,
            callback: function($$v) {
              _vm.modalBranches = $$v
            },
            expression: "modalBranches"
          }
        },
        [
          _vm.branchSelected === "origin"
            ? _c("b-form-checkbox-group", {
                attrs: {
                  options: _vm.branches,
                  "value-field": "id",
                  "text-field": "name",
                  stacked: ""
                },
                model: {
                  value: _vm.bundleProduct.originBranches,
                  callback: function($$v) {
                    _vm.$set(_vm.bundleProduct, "originBranches", $$v)
                  },
                  expression: "bundleProduct.originBranches"
                }
              })
            : _vm._e(),
          _vm.branchSelected === "destination"
            ? _c("b-form-checkbox-group", {
                attrs: {
                  options: _vm.branches,
                  "value-field": "id",
                  "text-field": "name",
                  stacked: ""
                },
                model: {
                  value: _vm.bundleProduct.destinationBranches,
                  callback: function($$v) {
                    _vm.$set(_vm.bundleProduct, "destinationBranches", $$v)
                  },
                  expression: "bundleProduct.destinationBranches"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }