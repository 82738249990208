<template>
    <b-container>
        <b-row class="mb-5">
            <b-col>
                <h3>{{ $t('admin.bussiness.bundle') }}</h3>
            </b-col>
            <b-col class="text-right">
                <b-button-group>
                    <b-dropdown
                        right
                        variant="primary"
                        class="btn-primary-dark"
                        :text="$t('admin.bussiness.btnBundle')"
                    >
                        <b-dropdown-item @click="openBundleModal('US')">USA</b-dropdown-item>
                        <b-dropdown-item @click="openBundleModal('MX')">MEX</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
                <!-- <b-button
                    variant="primary"
                    class="btn-primary-dark"
                    @click="createBundleModal = true"
                >
                    {{ $t('admin.bussiness.btnBundle') }}
                </b-button> -->
            </b-col>
        </b-row>
        <b-alert
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
        >
            <strong>{{ $t('admin.bundles.bundleCreated') }}</strong>
            {{ dismissCountDown }}
        </b-alert>

        <b-alert
            :show="dismissCountDownStatus"
            dismissible
            variant="success"
            @dismissed="dismissCountDownStatus = 0"
            @dismiss-count-down="countDownChangedStatus"
        >
            <strong>Bundle updated!</strong>
            {{ dismissCountDownStatus }}
        </b-alert>

        <b-table :items="items" :fields="fields" :busy="isBusy" head-variant="light" small>
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t('admin.bundles.loading') }}</strong>
                </div>
            </template>
            <template #cell(actions)="row">
                <b-button variant="primary" size="sm" @click="row.toggleDetails" class="py-0 mr-3">
                    {{ $t('admin.bundles.details') }}
                </b-button>
                <b-button
                    variant="warning"
                    size="sm"
                    @click="editBundle(row.item)"
                    class="py-0 mr-3"
                >
                    {{ $t('admin.bundles.edit') }}
                </b-button>
                <b-button
                    :variant="row.item.isActive ? 'danger' : 'success'"
                    size="sm"
                    @click="openModalStatus(row.item)"
                    class="py-0"
                >
                    {{
                        row.item.isActive ? $t('admin.bundles.disable') : $t('admin.bundles.enable')
                    }}
                </b-button>
            </template>

            <template #row-details="row">
                <b-card
                    v-for="item in row.item.phases"
                    :key="item._id"
                    header-bg-variant="primary"
                    header-text-variant="white"
                    class="mb-2"
                >
                    <template #header>
                        <span class="font-weight-bold">{{ item.phaseName }}</span>
                    </template>
                    <b-card-text>
                        <b-table
                            fixed
                            :items="item.products"
                            :fields="bundleFields"
                            responsive
                            small
                            bordered
                        >
                            <template #thead-top>
                                <tr>
                                    <th colspan="2" class="text-center">
                                        {{ $t('admin.bundles.branches') }}
                                    </th>
                                    <th colspan="2" class="text-center">
                                        {{ $t('admin.bundles.percentages') }}
                                    </th>
                                </tr>
                            </template>
                            <template #cell(isSingleBusiness)="data">
                                <b-icon
                                    :icon="data.item.isSingleBusiness ? 'check-lg' : 'x-lg'"
                                    :variant="data.item.isSingleBusiness ? 'success' : 'danger'"
                                    class="icon"
                                ></b-icon>
                            </template>
                            <template #cell(originBranches)="{item}">
                                <ul class="list-inline d-inline-block mb-2">
                                    <li
                                        v-for="tag in item.originBranches"
                                        :key="tag"
                                        class="list-inline-item"
                                    >
                                        <b-form-tag :title="tag" variant="warning" no-remove>
                                            {{ tag }}
                                        </b-form-tag>
                                    </li>
                                </ul>
                            </template>
                            <template #cell(destinationBranches)="{item}">
                                <ul class="list-inline d-inline-block mb-2">
                                    <li
                                        v-for="tag in item.destinationBranches"
                                        :key="tag"
                                        class="list-inline-item"
                                    >
                                        <b-form-tag :title="tag" variant="info" no-remove>
                                            {{ tag }}
                                        </b-form-tag>
                                    </li>
                                </ul>
                            </template>
                            <template #cell(price)="{ item }">
                                {{ getCurrency(item) }}
                            </template>
                        </b-table>
                    </b-card-text>
                </b-card>
            </template>
        </b-table>
        <b-modal
            v-model="createBundleModal"
            button-size="sm"
            cancel-variant="danger"
            ok-title="Save"
            ok-variant="blue-dark"
            size="xl"
            :title="`${$t('admin.bussiness.btnBundle')}`"
            no-close-on-backdrop
            no-close-on-esc
            scrollable
        >
            <Bundle
                ref="bundleComponent"
                :editing-bundle="editingBundle"
                :is-editing="isEditing"
                @save-bundle="createBundle"
            />
            <template #modal-footer>
                <b-alert :variant="bundleAlert.variant" :show="showBundleAlert" class="mr-5">
                    <b-icon icon="exclamation-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    {{ bundleAlert.message }}
                </b-alert>
                <b-button variant="outline-danger" @click="setCancel()" :disabled="loadingCreation">
                    {{ $t('admin.bundles.cancel') }}
                </b-button>
                <b-button v-if="!loadingCreation" variant="primary" @click="saveBundle">
                    {{ $t('admin.bundles.save') }}
                </b-button>
                <b-button v-else variant="primary" disabled>
                    <b-spinner small class="mr-2"></b-spinner>
                    <span>{{ $t('admin.bundles.createBundle') }}</span>
                </b-button>
            </template>
        </b-modal>
        <b-modal
            v-model="changeStatusBundleModal"
            button-size="sm"
            cancel-variant="danger"
            ok-title="Save"
            ok-variant="blue-dark"
            size="xs"
            title="Change status"
            no-close-on-backdrop
            no-close-on-esc
            scrollable
        >
            <p>
                Are you sure you want to change the status of the
                {{ changeStatusSelectedBundle ? changeStatusSelectedBundle.bundleName : '' }}
                bundle?
            </p>
            <template #modal-footer>
                <b-alert :variant="bundleAlert.variant" :show="showBundleAlert" class="mr-5">
                    <b-icon icon="exclamation-circle-fill" font-scale="1" class="mr-3"></b-icon>
                    {{ bundleAlert.message }}
                </b-alert>
                <b-button variant="outline-danger" @click="setCancel()" :disabled="loadingCreation">
                    {{ $t('admin.bundles.cancel') }}
                </b-button>
                <b-button v-if="!loadingCreation" variant="primary" @click="changeBundleStatus">
                    Change
                </b-button>
                <b-button v-else variant="primary" disabled>
                    <b-spinner small class="mr-2"></b-spinner>
                    <span>Updating...</span>
                </b-button>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import { imGetRequest, imPostRequest, imPutRequest } from '../../../api/imRequests'
import Bundle from './Bundle.vue'

export default {
    name: 'Bundles',
    components: { Bundle },

    data() {
        return {
            showAlert: false,
            showBundleAlert: false,
            loadingCreation: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            dismissCountDownStatus: 0,
            cantidad: 0,
            bundles: [],
            tableData: [],
            isBusy: false,
            createBundleModal: false,
            changeStatusBundleModal: false,
            bundleAlert: {
                message: '',
                variant: 'success'
            },
            bundle: {
                isActive: true,
                bundleName: null,
                country: null,
                phases: null
            },
            items: [],
            editingBundle: null,
            changeStatusSelectedBundle: null,
            isEditing: false,
            centralProducts: null
        }
    },
    computed: {
        fields() {
            return [
                {
                    key: 'bundleName',
                    label: this.$t('admin.bundles.name'),
                    tdClass: 'align-middle'
                },
                {
                    key: 'country',
                    label: 'Country',
                    tdClass: 'align-middle'
                },
                { key: 'actions', label: this.$t('admin.bundles.actions'), tdClass: 'align-middle' }
            ]
        },
        bundleFields() {
            return [
                { key: 'originBranches', label: this.$t('admin.bundles.origin') },
                { key: 'destinationBranches', label: this.$t('admin.bundles.destination') },
                { key: 'percentageOrigin', label: this.$t('admin.bundles.origin') },
                { key: 'percentageDestination', label: this.$t('admin.bundles.destination') },
                { key: 'isSingleBusiness', label: this.$t('admin.bundles.single') },
                { key: 'productName', label: this.$t('admin.bundles.productName') },
                { key: 'price', label: this.$t('admin.bundles.price') }
            ]
        }
    },
    methods: {
        openModalStatus(bs) {
            this.changeStatusSelectedBundle = bs
            this.changeStatusBundleModal = true
        },
        async changeBundleStatus() {
            const { status } = await imPutRequest('bundles', {
                id: this.changeStatusSelectedBundle._id
            })
            if (status === 201) {
                this.setCancel()
                this.dismissCountDownStatus = this.dismissSecs
            }
        },
        openBundleModal(country) {
            this.isEditing = false
            this.editingBundle = null
            sessionStorage.setItem('countryBundle', country)
            this.createBundleModal = true
        },
        setCancel() {
            this.createBundleModal = false
            this.changeStatusBundleModal = false
            this.loadBundles()
        },
        async getCentralProducts() {
            const { data } = await imGetRequest('bundles/products?categoryId=38,43')
            this.centralProducts = data.sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
        },
        getCurrency(product) {
            if (this.centralProducts.length) {
                const { moneda_id } = this.centralProducts.find(cp => cp.prod_id === product.id)

                const currency = moneda_id === 1 ? 'MXN' : 'USD'
                const productvalue = product.price.toLocaleString(
                    moneda_id === 1 ? 'es-MX' : 'en-US',
                    {
                        style: 'currency',
                        currency
                    }
                )

                return `${currency} ${productvalue}`
            }
        },
        async loadBundles() {
            this.isBusy = true
            this.items = []

            const { status, data } = await imGetRequest('bundles')
            if (status === 201) {
                this.items = data
            }

            this.isBusy = false
        },
        validateFields(obj) {
            for (const key in obj) {
                const value = obj[key]

                if (value === null) {
                    return false
                }

                if (Array.isArray(value) && value.length === 0) {
                    return false
                }

                if (typeof value === 'object' && value !== null) {
                    if (!this.validateFields(value)) {
                        return false
                    }
                }
            }

            return true
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        countDownChangedStatus(dismissCountDown) {
            this.dismissCountDownStatus = dismissCountDown
        },
        async createBundle(bundle, isEditing) {
            this.showBundleAlert = false
            this.loadingCreation = true

            const valid = this.validateFields(bundle)
            if (!valid) {
                this.bundleAlert = {
                    message: 'All fields in a bundle are required',
                    variant: 'danger'
                }
                this.showBundleAlert = true
                this.loadingCreation = false
                return
            }

            this.isBusy = true

            bundle.isEditing = isEditing
            bundle.country = sessionStorage.getItem('countryBundle')

            const { status, data } = await imPostRequest('bundles', bundle)

            if (status === 200) {
                this.bundleAlert = {
                    message: data,
                    variant: 'warning'
                }
                this.showBundleAlert = true
                this.loadingCreation = false
                return
            } else if (status === 201) {
                this.items = data
                this.dismissCountDown = this.dismissSecs
                sessionStorage.removeItem('countryBundle')
            }

            this.loadBundles()
            this.loadingCreation = false
            this.createBundleModal = false
        },
        editBundle(bundle) {
            const selectedBundle = this.items.find(item => item._id === bundle._id)
            sessionStorage.setItem('countryBundle', selectedBundle.country)

            this.editingBundle = { ...selectedBundle }
            this.isEditing = true
            this.createBundleModal = true
        },
        saveBundle() {
            this.$refs.bundleComponent.emitBundle()
        }
    },
    mounted() {
        this.getCentralProducts()
        this.loadBundles()
    }
}
</script>

<style scoped>
td {
    padding: 0;
}
</style>
