<template>
    <b-container class="bg-color rounded-lg py-3 ">
        <b-row>
            <b-col cols="4" class="py-2">
                <b-form-select v-model="bundleProduct.id" size="sm">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ $t('admin.bundles.select') }}
                        </b-form-select-option>
                    </template>
                    <b-form-select-option
                        v-for="product in products"
                        :key="product.label"
                        :label="product.label"
                        :value="product.prod_id"
                    >
                        {{ product.prod_desc }}
                    </b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col cols="3" class="py-2">
                <b-button size="sm" variant="dark" @click="openModalBranches('origin')">
                    {{ $t('admin.bundles.chooseOrigins') }}
                </b-button>
            </b-col>
            <b-col v-if="bundleProduct.isSingleBusiness" cols="5" class="py-2">
                <b-input-group
                    :prepend="`O: ${bundleProduct.percentageOrigin.toString()}%`"
                    :append="`D: ${(100 - bundleProduct.percentageOrigin).toString()}%`"
                >
                    <b-form-input
                        v-model="bundleProduct.percentageOrigin"
                        id="range-2"
                        number
                        type="range"
                        min="0"
                        max="100"
                    ></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="py-2">
                <b-form-checkbox switch v-model="bundleProduct.isSingleBusiness">
                    {{ $t('admin.bundles.singleBusiness') }}
                </b-form-checkbox>
            </b-col>
            <b-col cols="3" class="py-2">
                <b-button size="sm" variant="dark" @click="openModalBranches('destination')">
                    {{ $t('admin.bundles.chooseDestinations') }}
                </b-button>
            </b-col>
            <b-col cols="5" class="py-2">
                <b-button size="sm" variant="primary" @click="addProduct">
                    {{ $t('admin.bundles.addProduct') }}
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            v-model="modalBranches"
            button-size="sm"
            cancel-variant="danger"
            ok-title="Add"
            ok-variant="primary"
            size="sm"
            scrollable
        >
            <template #modal-header>
                <h6>Choose branches</h6>
            </template>
            <b-form-checkbox-group
                v-if="branchSelected === 'origin'"
                v-model="bundleProduct.originBranches"
                :options="branches"
                value-field="id"
                text-field="name"
                stacked
            ></b-form-checkbox-group>
            <b-form-checkbox-group
                v-if="branchSelected === 'destination'"
                v-model="bundleProduct.destinationBranches"
                :options="branches"
                value-field="id"
                text-field="name"
                stacked
            ></b-form-checkbox-group>
        </b-modal>
    </b-container>
</template>

<script>
import { imGetRequest } from '../../../api/imRequests'

export default {
    name: 'BundleProduct',
    data() {
        return {
            modalBranches: false,
            branchSelected: null,
            products: [],
            branches: [],
            bundleProduct: {
                id: null,
                originBranches: [],
                destinationBranches: [],
                isSingleBusiness: false,
                productName: null,
                price: null,
                percentageOrigin: 0,
                percentageDestination: 0
            }
        }
    },

    created() {
        this.getData()
    },

    methods: {
        async getData() {
            const country = sessionStorage.getItem('countryBundle') === 'US' ? 43 : 38
            const products = await imGetRequest(`bundles/products?categoryId=${country}`)
            const { data } = await imGetRequest('hubspot/branches')

            const sucursalesMx = data.sucursalesMx
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))

            const sucursalesUs = data.sucursalesUs
                .map(({ id, name }) => ({ id, name }))
                .sort((a, b) => (a.name > b.name ? 1 : -1))

            this.branches = [...sucursalesMx, ...sucursalesUs]
            this.products = products.data.sort((a, b) => (a.articulo > b.articulo ? 1 : -1))
        },
        openModalBranches(option) {
            this.branchSelected = option
            this.modalBranches = true
        },
        findProductByDescription(id, field) {
            return this.products.flatMap(product => product).find(option => option.prod_id === id)[
                field
            ]
        },
        addProduct() {
            this.bundleProduct.price = this.findProductByDescription(
                this.bundleProduct.id,
                'prod_preciolista'
            )
            this.bundleProduct.productName = this.findProductByDescription(
                this.bundleProduct.id,
                'prod_desc'
            )

            if (this.bundleProduct.isSingleBusiness) {
                this.bundleProduct.percentageDestination = 100 - this.bundleProduct.percentageOrigin
            }

            this.$emit('product', this.bundleProduct)
        }
    }
}
</script>

<style scoped>
.bg-color {
    background-color: #b8daff;
}
</style>
